"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  // Options.jsx
  items_per_page: 'na stronę',
  jump_to: 'Idź do',
  jump_to_confirm: 'potwierdź',
  page: '',
  // Pagination.jsx
  prev_page: 'Poprzednia strona',
  next_page: 'Następna strona',
  prev_5: 'Poprzednie 5 stron',
  next_5: 'Następne 5 stron',
  prev_3: 'Poprzednie 3 strony',
  next_3: 'Następne 3 strony',
  page_size: 'rozmiar strony'
};
exports.default = _default;