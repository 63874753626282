import React, { useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import Login from './pages/login';
import PanelLoading from './pages/panel_loading/panel_loading';
import locale from 'antd/lib/locale/pl_PL';

import 'antd/dist/antd.css';
import './styles/app.scss';

const App = () => {
	const [panel, setPanel] = useState<any>();

	const onLogin = async () => {
		const panel = await import('./pages/panel');
		const Panel = panel.default;

		setPanel(<Panel />);
	};

	return (
		<ConfigProvider locale={locale}>
			<Router>
				<Switch>
					<Route exact path="/">
						<Login onLogin={onLogin} />
					</Route>
					<Route exact path="/logout">
						<Login onLogin={onLogin} />
					</Route>
					<Route path="/panel">{panel ?? <PanelLoading onLoaded={onLogin} />}</Route>
				</Switch>
			</Router>
		</ConfigProvider>
	);
};

export default App;
